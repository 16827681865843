import { hasEnglishVersion } from "../resources/menu/de/hasEnglishVersion";
import getPrefixKey from "./getPrefixKey";

const isVisibleLanguageSwitch = () => {
  const lastSubfolderOfSlug = window.location.pathname
    .split("/")
    .filter((item) => item) // removes empty strings from resulting Array
    .pop();

  const prefixKey = getPrefixKey();

  const isVisible = hasEnglishVersion(lastSubfolderOfSlug)
                 || prefixKey === "en"
                 || prefixKey === "neu";
  return isVisible;
};

export default isVisibleLanguageSwitch;
