import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { t } from 'i18next';

import Brand from '../Brand/Brand';
import DynamicLink from '../DynamicLink';
import SvgIconSyncwork from '../Logos/SvgIconSyncwork';
import SvgLogoTechbar from '../Logos/SvgLogoTechbar';

// store
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './MainMenu.scss';
import getDepartmentKey from '../../utils/getDepartmentKey';
import { selectSecondaryMenu } from '../../state/store/slices/secondaryMenuSlice';
import { formatUrl } from '../../utils';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import i18n from '../../i18n';
import getPrefixKey from '../../utils/getPrefixKey';
import { getMenuTranslations } from '../../resources/menu/de/hasEnglishVersion';

const MainMenuItems = ({
  menuItems,
  toggleActiveMenu,
  active,
}) => {
  const menuTranslations = (getPrefixKey() === "en") ? getMenuTranslations() : {};
  return (
    <>
      {
        menuItems.map(menuItem => (
          <div key={menuItem.id}>
            {
              menuItem.url ? (
                (menuItem.url === 'https://syncwork.de/' || menuItem.url === 'https://www.syncwork.de/') ? (
                  <a href="https://syncwork.de/">
                    {/*
                      When on Landingpage, the last item is usually this link to the syncwork homepage.
                      Want google to follow the links to the homepage (Syncwork AG), <DynamicLink> + "https://" = rel="nofollow"
                    */}
                    <span className="homepage-link">{menuItem.title}</span>
                  </a>
                ) : (
                  <DynamicLink to={menuItem.url}>
                    {/* ordinary Menu Item without dropdown */}
                    <span>{menuItem.title}</span>
                  </DynamicLink>
                )
              ) : (
                <>
                  {
                      menuItem.pages.length === 1 && !menuItem.pages[0].hashFragments.length ? (
                        <DynamicLink key={menuItem.pages[0].id} to={menuItem.pages[0].slug}>
                          {/* ordinary Menu Item without dropdown (not from the URL-input-field, but as one "Page" */}
                          <span>{menuItem.pages[0].menuLabel}</span>
                        </DynamicLink>
                      ) : (
                        <>
                          {/* button with dropdown */}
                          <button
                            className="toggle"
                            onBlur={() => toggleActiveMenu(null)}
                            onClick={() => toggleActiveMenu(menuItem.id)}
                            type="button"
                          >
                            <span>{menuItem.title}</span>
                            <i className={menuItem.id === active ? 'icon icon-chevron-up' : 'icon icon-chevron-down'} />
                          </button>
                          <div className="menu-sub">
                            <div className={menuItem.id === active ? 'drop drop-active' : 'drop'}>
                              {
                                menuItem.pages.map(page => {
                                  if (page.hashFragments.length) {
                                    return (
                                      page.hashFragments.map(hashFragment => {
                                        const url = (hashFragment.fragment) ? `/${page.slug}#${hashFragment.fragment}` : `/${page.slug}#root`;

                                        return (
                                          <DynamicLink
                                            key={hashFragment.id}
                                            to={url}
                                          >
                                            <span>{hashFragment.title}</span>
                                          </DynamicLink>
                                        );
                                      })
                                    );
                                  }
                                  return (
                                    <DynamicLink
                                      key={page.id}
                                      to={`/${menuTranslations[page.slug] || page.slug}`}
                                    >
                                      {menuTranslations[page.menuLabel] || page.menuLabel}
                                    </DynamicLink>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </>
                      )
                    }
                </>
              )
              }
          </div>
        ))
      }
    </>
  );
};

const SecondaryMenuItems = ({ secondaryMenu, toggleActiveMenu, active }) => {
  const { menuItems, title } = secondaryMenu;
  const secondaryMenuTitle = title?.replace(/\((.+?)\)/g, "");
  const menuTranslations = (getPrefixKey() === "en") ? getMenuTranslations() : {};

  return (
    <>
      <button
        className="toggle"
        onBlur={() => toggleActiveMenu(null)}
        onClick={() => toggleActiveMenu(0)}
        type="button"
      >
        <span>{secondaryMenuTitle}</span>
        <i className={active === 0 ? 'icon icon-chevron-up' : 'icon icon-chevron-down'} />
      </button>
      <div className="menu-sub">
        <div className={active === 0 ? 'drop drop-active' : 'drop'}>
          {/* <DynamicLink to={`/${secondaryMenu.subDomainKey}`}>
            <span>{secondaryMenu.title} - {t("Übersicht")}</span>
          </DynamicLink> */}
          {menuItems.map(menuItem => {
            let slash = "/";
            if (["#", "/"].includes(menuItem.url?.charAt(0))) {
              slash = "";
            }

            // console.log(menuItem.pages[0]?.hashFragments.length, menuItem);
            return (
              <span key={menuItem.id}>
                {
                  menuItem.url ? (
                    <>
                      {
                        menuItem.url.includes("https://") || menuItem.url.includes("http://") ? (
                          <DynamicLink to={menuItem.url}>
                            <span>{menuItem.title}</span>
                          </DynamicLink>
                        ) : (
                          <DynamicLink to={`${slash}${menuItem.url}`}>
                            {/* hier muss ich wieder den subDomainKey einfügen für techbar & karriere */}
                            {/* Seiten mit Sprungmarken # funktionieren auch noch nicht so gut */}
                            <span>{menuItem.title}</span>
                          </DynamicLink>
                        )
                      }
                    </>
                  ) : (
                    <>
                      {
                        menuItem.pages.length === 1 && !menuItem.pages[0].hashFragments.length ? (
                          <DynamicLink key={menuItem.pages[0].id} to={`${slash}${menuItem.pages[0].slug}`}>
                            <span>{menuItem.pages[0].menuLabel}</span>
                          </DynamicLink>
                        ) : (
                          menuItem.pages.map(page => {
                            if (page.hashFragments?.length) {
                              // console.log(page.hashFragments, "wird jetzt hier nicht ausgegeben, stattdessen der Link zur Page, die eingefügt wurde."); // , "what are hash Fragments ?!?"); siehe https://siem.syncwork.de/
                            }
                            const slug = menuTranslations[page.slug] || page.slug;
                            const label = menuTranslations[page.title] || page.menuLabel || page.title;
                            return (
                              <Link
                                key={page.id}
                                to={`${slash}${slug}`}
                              >
                                {label}
                              </Link>
                            );
                          })
                        )
                      }
                    </>
                  )
                }
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};

/**
 * @return {html}
 */
const MainMenu = () => {
  const location = useLocation();

  const mainMenu = useAppSelector(selectMainMenu);
  const secondaryMenu = useAppSelector(selectSecondaryMenu);

  const urlPathname = location.pathname
    .split("/")
    .filter((item) => item); // removes empty strings from resulting Array

  const [activeMenu, setActiveMenu] = useState(null);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);

  const toggleMobileMenu = (isActive?) => {
    if (!activeMenu && secondaryMenu) {
      setActiveMenu(secondaryMenu.title);
    } else {
      setActiveMenu(null);
    }
    const mobileMenuState = (typeof isActive === 'undefined') ? !activeMobileMenu : isActive;
    setActiveMobileMenu(mobileMenuState);
  };

  const toggleActiveMenu = (id) => {
    setTimeout(() => {
      if (id === activeMenu) {
        setActiveMenu(null);
      } else {
        setActiveMenu(id);
      }
    }, 100);
  };

  useEffect(() => {
    toggleMobileMenu(false);
    i18n.changeLanguage(urlPathname[0] === "en" ? "en" : "de");
  }, [location]);

  if (!mainMenu) {
    return <></>;
  }

  const theme = mainMenu.theme.toLowerCase();

  const secondaryMenuTitle = secondaryMenu?.title?.replace(/\((.+?)\)/g, "");

  return (
    <nav className={`sw-main-menu ${activeMenu ? 'sw-main-menu-active' : ''}`.trim()}>
      <div className="menu-container">
        <div className="menu-brand">
          {
            (theme === 'techbar') ? (
              <Link to="/">
                <Brand
                  iconComponent={(
                    <SvgLogoTechbar
                      color="#fff"
                      height="1.2rem"
                    />
                  )}
                  suffix={null}
                />
              </Link>
            ) : (
              <>
                <Link to={formatUrl("/")}>
                  <Brand
                    iconComponent={(
                      <SvgIconSyncwork
                        color="#fff"
                        height="1.5rem"
                      />
                    )}
                    suffix={!getDepartmentKey() ? mainMenu.title : null}
                  />
                </Link>
                {secondaryMenu ? (
                  <div className="suffix">
                    <div>
                      <DynamicLink to={`/${secondaryMenu.subDomainKey}`}>
                        <div>{secondaryMenuTitle}</div>
                        {activeMobileMenu &&
                          <span className="secondary-menu-home-hint">&nbsp;- {t("common.overview")}</span>
                        }
                      </DynamicLink>
                    </div>
                  </div>
                ) : (
                  <>
                    {(mainMenu.subDomainKey && mainMenu.subDomainKey !== "www") ? (
                      <div className="onlyVisibleForDesktop">{mainMenu.title}</div>
                    ) : (<></>)}
                  </>
                )}
              </>
            )
          }
          {secondaryMenu ? (
            <div className="menu-topic-area-desktop">
              <div>
                <SecondaryMenuItems
                  secondaryMenu={secondaryMenu}
                  toggleActiveMenu={toggleActiveMenu}
                  active={activeMenu}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="menu-top-desktop">
          <MainMenuItems
            menuItems={mainMenu.menuItems}
            toggleActiveMenu={toggleActiveMenu}
            active={activeMenu}
          />

          <LanguageSwitch />
        </div>

        {/* secondaryMenu && !isLive && (
          <button className="search-button" type="button">
            <IconSearch title="Search" titleId="titleId" fill="#fff" width="2rem" height="2rem" />
          </button>
        ) */}

        <div className="menu-top-mobile">
          <div className="toggle">
            <button
              className={`hamburger hamburger--slider ${activeMobileMenu ? 'is-active' : ''}`.trim()}
              type="button"
              onClick={() => toggleMobileMenu()}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <div className={`menu-flyout ${activeMobileMenu ? 'is-active' : ''}`.trim()}>
            {(secondaryMenu) && (
              <div className="menu-topic-area-mobile">
                <SecondaryMenuItems
                  secondaryMenu={secondaryMenu}
                  toggleActiveMenu={toggleActiveMenu}
                  active={activeMenu}
                />
              </div>
            )}
            <MainMenuItems
              menuItems={mainMenu.menuItems}
              toggleActiveMenu={toggleActiveMenu}
              active={activeMenu}
            />

            <LanguageSwitch />
          </div>
        </div>

      </div>
    </nav>
  );
};

export default MainMenu;
