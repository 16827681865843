import { Link, useLocation } from 'react-router-dom';
import "./LanguageSwitch.scss";
import isVisibleLanguageSwitch from '../../utils/isVisibleLanguageSwitch';
import getPrefixKey from '../../utils/getPrefixKey';
import { getEnglishLink, getGermanLink } from '../../resources/menu/de/hasEnglishVersion';

/**
 * @return {html}
 */
const LanguageSwitch = () => {
  const location = useLocation();
  let changeLanguageLink = location.pathname
    .split("/")
    .filter((item) => item); // removes empty strings from resulting Array

  const link = changeLanguageLink.join("/");
  const germanVersion = getGermanLink(link);
  const englishVersion = getEnglishLink(link);

  // if the current page is not in our hasEnglishVersion list, add or remove the "en/" prefix
  const prefixKeys = getPrefixKey();
  if (prefixKeys.includes(changeLanguageLink[0])) {
    if (changeLanguageLink[0] !== "en") {
      changeLanguageLink[0] = "en";
    } else {
      changeLanguageLink.shift();
    }
  } else {
    changeLanguageLink.unshift("en");
  }

  changeLanguageLink = germanVersion || englishVersion || `/${changeLanguageLink.join("/")}`;

  // Here we control temporarily on wich pages the language switch appears
  if (!isVisibleLanguageSwitch()) {
    return <></>;
  }

  return (
    <div className="language-switch" title="Language switch">
      <Link to={`${changeLanguageLink}`} className="language-switch-link">
        {changeLanguageLink[0] === "en" ?
          (<><strong>de</strong> | en</>) :
          (<>de | <strong>en</strong></>)
        }
      </Link>
    </div>
  );
};

export default LanguageSwitch;
