/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './MegaMenu.scss';
import { formatUrl } from '../../utils';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import i18n from '../../i18n';
import { menu } from '../../resources/menu/de/menu';
import DynamicLink from '../DynamicLink';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Brand from '../Brand/Brand';
import SvgIconSyncwork from '../Logos/SvgIconSyncwork';

/*
const printNameOfFunction = (name, event = null) => {
  console.log(name, event);
};
*/

const closeAllMenus = () => {
  // printNameOfFunction("closeAllMenus");
  const divlist = document.querySelectorAll("li.active");
  Array.from(divlist).map((activeListItem) => activeListItem.classList.remove("active"));
  document.querySelector(".sw-main-menu").classList.remove("sw-main-menu-active");
};

const closeAllMenusOnSameLevelAndBelow = (target) => {
  // printNameOfFunction("closeAllMenusOnSameLevelAndBelow");
  const divlist = target.closest("ul").querySelectorAll(":scope li.active");
  const divarray = [...divlist];
  divarray.map((activeListItem) => activeListItem.classList.remove("active"));
};

const toggleSubmenu = (event) => {
  // printNameOfFunction("toggleSubmenu");
  const target = event.target.closest("li");
  const targetIsActive = target.classList.contains("active");

  closeAllMenusOnSameLevelAndBelow(target);

  if (targetIsActive) {
    target.classList.remove("active");
  } else {
    target.classList.add("active");
  }
};

const isHamburgerMenuHidden = () => {
  // printNameOfFunction("isHamburgerMenuHidden");
  const hamburger = document.querySelector(".hamburger");
  const styles = window.getComputedStyle(hamburger);
  return styles.display === "none";
};

const isTouchDevice = () => {
  // printNameOfFunction("isTouchDevice");
  const megaMenu = document.querySelector(".mega-menu");
  return megaMenu.classList.contains("touch-event");
};

const showSubmenu = (event) => {
  // printNameOfFunction("showSubmenu");
  const target = event.target.closest("li");
  closeAllMenusOnSameLevelAndBelow(target);
  target.classList.add("active");
};

const closeTopmenu = (event) => {
  // printNameOfFunction("closeTopmenu");
  const target = event.target.closest("li");
  const parent = target.closest("li.active");
  parent?.classList.remove("active");
};

const onMouseLeave = (event) => {
  // printNameOfFunction("onMouseLeave");

  if (!isTouchDevice()) {
    const target = event.target;
    if (target.classList.contains("list-level-1")) {
      closeTopmenu(event);
    }
    closeAllMenusOnSameLevelAndBelow(target);
  }
};

const onMouseEnter = (event) => {
  // printNameOfFunction("onMouseEnter", event);
  if (isHamburgerMenuHidden() && !isTouchDevice()) { // on mobile and touch devices we dont want changes on hover
    showSubmenu(event);
  }
};

const onClick = (event) => {
  // printNameOfFunction("onClick");
  toggleSubmenu(event);
};

const onClickNavigation = (event) => {
  // printNameOfFunction("onClickNavigation");
  const eventTarget = event.target;
  const isClickOnBackdrop = eventTarget.classList.contains("list-level-1");
  if (isClickOnBackdrop) {
    closeAllMenus();
  }
};

const iterateOverMenuItems = (submenu: any, level: number) => {
  const html = submenu.map((menuItem, index) => (
    <li key={menuItem.name} className={`${menuItem.url} item-level-${level}`} style={{ "--animation-order": index } as React.CSSProperties} onMouseLeave={onMouseLeave}>
      { menuItem.children ? (
        <>
          <button className="js-toggle-submenu" type="button" onMouseEnter={onMouseEnter} onClick={onClick}>
            {menuItem.name}
            <i className="icon icon-chevron-down"></i>
          </button>
          <ul className={`list-unstyled list-level-${level + 1}`}>
            <li className="back-button">
              <button type="button" onClick={closeTopmenu}>
                <i className="icon icon-chevron-down"></i>zurück
              </button>
            </li>
            { !!menuItem.url &&
              <li className="heading"><a href={menuItem.url}>{menuItem.name}</a></li>
            }
            { iterateOverMenuItems(menuItem.children, level + 1) }
          </ul>
        </>
      ) : (
        <DynamicLink to={menuItem.url}>
          {menuItem.name}
        </DynamicLink>
      )}
    </li>
  ));
  return html;
};

/**
 * @return {html}
 */
const MegaMenu = () => {
  const location = useLocation();
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);

  useEffect(() => {
    // click outside the Navigation closes the Navigation
    const handleClick = (event: MouseEvent) => {
      const parentContainer = (event.target as HTMLElement).closest(".mega-menu-container");
      if (!parentContainer) {
        closeAllMenus();
      }
    };

    const handleTouchevent = (event: TouchEvent) => {
      const megaMenu = (event.target as HTMLElement).closest(".mega-menu");
      megaMenu?.classList.add("touch-event");
    };

    document.addEventListener("click", handleClick, true);
    document.addEventListener("touchstart", handleTouchevent, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
      document.removeEventListener("touchstart", handleTouchevent, true);
    };
  }, []);

  const urlPathname = location.pathname
    .split("/")
    .filter((item) => item); // removes empty strings from resulting Array

  const toggleMobileMenu = () => {
    const mobileMenuState = !activeMobileMenu;
    setActiveMobileMenu(mobileMenuState);
  };

  useEffect(() => {
    setActiveMobileMenu(false);
    closeAllMenus();
    i18n.changeLanguage(urlPathname[0] === "en" ? "en" : "de");
  }, [location]);

  return (
    <nav className={`mega-menu-container sw-main-menu ${activeMobileMenu ? 'mega-menu-container-active sw-main-menu-active' : ''}`.trim()}>
      <div className="menu-container">
        <div className="menu-brand">
          <Link to={formatUrl("/")}>
            <Brand
              iconComponent={(
                <SvgIconSyncwork
                  color="#fff"
                  height="1.5rem"
                />
              )}
            />
          </Link>
        </div>

        <ul className={`mega-menu list-unstyled  ${activeMobileMenu ? 'mega-menu-active' : ''}`} onClick={onClickNavigation}>
          { iterateOverMenuItems(menu, 0) }
        </ul>
        <HamburgerMenu toggleMobileMenu={toggleMobileMenu} activeMobileMenu={activeMobileMenu} />

        <div className="extra-controls">
          <Link to="kontakt" className="kontakt" style={{ "--animation-order": menu.length } as React.CSSProperties}>Kontakt</Link>
          <LanguageSwitch />
        </div>

      </div>
    </nav>
  );
};

export default MegaMenu;
