export const menuLabelMapping = {
  "Digital Validation": "Digital validation",
  Finanzdienstleister: "Financial service provider",
  Industrie: "Industry",
  Informationstechnologie: "Information technology",
  "Life Science": "Life Science",
  "Öffentlicher Sektor": "Public Sector",
  scarabPLUS: "scarabPLUS",
  Telekommunikation: "Telecommunication",
  "Testmanagement im Life Science-Sektor": "Test management in the life science sector",
};

const urlMapping = {
  "digital-validation": "en/digital-validation",
  finanzdienstleister: "en/financial-service-provider",
  industrie: "en/industry",
  informationstechnologie: "en/information-technology",
  "life-science": "en/life-science",
  "oeffentlicher-sektor": "en/public-sector",
  rpa: "en/rpa",
  scarabplus: "en/scarabplus",
  syncwork: "en/syncwork",
  telekommunikation: "en/telecommunication",
  "testmanagement-life-science": "en/testmanagement-life-science",
};

export const hasEnglishVersion = (germanSlug: string) => {
  if (urlMapping[germanSlug]) return true;
  return false;
};

export const getGermanLink = (englishLink: string) => {
  let germanSlug = null;
  Object.keys(urlMapping).forEach((key) => {
    if (urlMapping[key] === englishLink) {
      germanSlug = `/${key}`;
    }
  });
  return germanSlug;
};

export const getEnglishLink = (germanSlug: string) => {
  if (urlMapping[germanSlug]) {
    return `/${urlMapping[germanSlug]}`;
  }
  return null;
};

export const getMenuTranslations = () => {
  const menuTranslations = { ...urlMapping, ...menuLabelMapping };
  return menuTranslations;
};

// export default hasEnglishVersion;
